<template>
  <div class="header">
    <div class="header-xl hidden-xs-only">
      <img src="~img/logo.png">
      <ul>
        <li v-for="item in list" :key="item.id" :class="{active:item.path == path}"> <span
            @click="juap(item)">{{item.name}}</span> </li>
        <li @click="go"> <span>科创星球</span> </li>
      </ul>
    </div>
    <div class="header-sm hidden-sm-and-up">
      <img src="~img/logo.png">
      <van-icon name="wap-nav" @click="showNav" />
    </div>
    <div class="drawer hidden-sm-and-up">
      <ul :class="{ul_animation : drawer}">
        <li :class="{active:item.path == path}" v-for="item in list" :key="item.id" @click="juap(item)">{{item.name}}
        </li>
        <li @click="go">科创星球</li>
      </ul>
    </div>
  </div>
</template>


<script>
import "element-ui/lib/theme-chalk/display.css";
export default {
  props: ["path"],
  data() {
    return {
      list: [
        {
          id: 1,
          name: "首页",
          path: "/",
        },
        {
          id: 2,
          name: "学前阶段",
          path: "/preschool",
        },
        {
          id: 3,
          name: "小学阶段",
          path: "/school",
        },
        {
          id: 4,
          name: "中心介绍",
          path: "/strategy",
        },
        {
          id: 5,
          name: "合作共赢",
          path: "/cooperation",
        },
        {
          id: 6,
          name: "关于我们",
          path: "/about",
        },
      ],
      id: "",
      drawer: false,
    };
  },
  created() {},
  watch: {},
  methods: {
    juap(item) {
      this.id = item.id;
      this.drawer = false;
      this.$router.push({ path: item.path });
    },
    showNav() {
      this.drawer === true ? (this.drawer = false) : (this.drawer = true);
    },
    go() {
      location.href = "http://www.steamkid.com/index";
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 999;
  background: #fff;
  .header-xl {
    height: 1.17rem;
    width: 100%;
    position: relative;
    img {
      width: 1.84rem;
      position: absolute;
      top: 50%;
      left: 4%;
      transform: translate(0, -50%);
    }
    ul {
      display: flex;
      height: 100%;
      width: 65%;
      position: absolute;
      right: 6%;
      align-items: center;
      justify-content: space-between;
      li {
        font-size: 0.2rem;
        color: #3e3a39;
        cursor: pointer;
      }
      .active {
        span {
          padding-bottom: 0.1rem;
          border-bottom: 1px solid #1989fa;
          color: #1989fa;
        }
      }
    }
  }
  .header-sm {
    height: 45px;
    width: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5% 0 5%;
    position: relative;
    z-index: 999;
    .van-icon {
      font-size: 24px;
    }
    img {
      width: 100px;
    }
  }
  .drawer {
    ul {
      width: 100%;
      position: absolute;
      right: 0;
      top: -270px;
      z-index: 9;
      opacity: 1;
      transition: 0.5s all;
      li {
        height: 45px;
        line-height: 45px;
        padding-left: 40px;
        font-size: 16px;
        border-bottom: 1px solid #cccccc;
        color: #ccc;
        background-color: #fff;
      }
      .active {
        color: #1989fa;
      }
    }
    .ul_animation {
      top: 45px;
    }
  }
}
</style>