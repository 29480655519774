<template>
  <div class="consulting">
    <div class="hidden-xs-only">
      <div class="information">
        <ul>
          <li>
            <img src="~img/110.png" class="zixun">
            <img src="~img/171.jpeg" class="erweim">
          </li>
          <li @click="changAudition(1)">
            <img src="~img/111.png">
          </li>
          <li @click="changPeration(2)">
            <img src="~img/112.png">
          </li>
          <li>
            <img src="~img/113.png">
          </li>
        </ul>
      </div>
      <div v-show="showAudition" class="Audition" :style="showStyle">
        <img src="~img/116.png" class="close" @click="showAudition = false">
        <img src="~img/114.png" class="a_bg" v-show="show">
        <img src="~img/180.png" class="b_bg" v-show="!show">
        <ul>
          <li>
            <span>姓 名</span>
            <input type="text" v-model="name" :placeholder="namep" :style="nameStyle"
              @focus='nameStyle = "",namep = ""'>
          </li>
          <li v-show="show">
            <span>年 龄</span>
            <input type="text" v-model="age">
          </li>
          <li>
            <span>电 话</span>
            <input type="text" v-model="mobile" :placeholder="mobilep" :style="mobileStyle"
              @focus='mobileStyle = "",mobilep = ""'>
          </li>
          <li>
            <span>所在城市</span>
            <v-distpicker :province="province" :city="city" :hide-area='true' @selected="onSelected">
            </v-distpicker>
          </li>
          <li v-show="show">
            <span>详细地址</span>
            <input type="text" v-model="address" :placeholder="addressp" :style="addressStyle"
              @focus='addressStyle="",addressp=""'>
          </li>
        </ul>
        <button @click="applyAudition"></button>
      </div>
    </div>
    <!-- web -->
    <div class="hidden-sm-and-up" v-if="showWeb">
      <div class="web-info">
        <img src="~img/01.png" class="bg-img" v-show="showone">
        <img src="~img/02.png" class="bg-img" v-show="!showone">
        <img src="~img/03.png" class="clous" @click="showWeb = false">
        <div class="btn-taggle" @click="taggle"></div>
        <a href="tel:400-600-1581">
          <div class="Telephone"></div>
        </a>
        <div class="input-box">
          <ul>
            <li>
              <span>姓 名</span>
              <input type="text" v-model="name" :placeholder="namep" :style="nameStyle"
                @focus='nameStyle = "",namep = ""'>
            </li>
            <li v-show="showone">
              <span>年 龄</span>
              <input type="text" v-model="age">
            </li>
            <li>
              <span>电 话</span>
              <input type="text" v-model="mobile" :placeholder="mobilep" :style="mobileStyle"
                @focus='mobileStyle = "",mobilep = ""'>
            </li>
            <li>
              <span>所在城市</span>
              <v-distpicker :province="province" :city="city" :hide-area='true' @selected="onSelected">
              </v-distpicker>
            </li>
            <li v-show="showone">
              <span>详细地址</span>
              <input type="text" v-model="address" :placeholder="addressp" :style="addressStyle"
                @focus='addressStyle="",addressp=""'>
            </li>
          </ul>
          <img src="~img/04.png" class="btn-submit" @click="applyAudition">
        </div>
      </div>
    </div>
  </div>

</template>


<script>
import VDistpicker from "v-distpicker";
export default {
  components: {
    VDistpicker,
  },
  data() {
    return {
      showAudition: false,
      show: true,
      name: "", // 姓名
      namep: "",
      age: "", // 年龄
      mobile: "", // 电话
      mobilep: "",
      address: "", // 详细地址
      addressp: "",
      province: "", // 省
      city: "", // 市
      nameStyle: "",
      mobileStyle: "",
      addressStyle: "",
      showStyle: "",
      num: 1,
      showone: true,
      showWeb: true,
    };
  },
  methods: {
    taggle() {
      this.showone === true
        ? ((this.showone = false), (this.num = 2))
        : ((this.showone = true), (this.num = 1));
      console.log(this.num);
    },
    onSelected(data) {
      this.province = data.province.value;
      this.city = data.city.value;
    },
    changAudition(num) {
      this.show = true;
      this.showStyle = "";
      this.showAudition = true;
      this.num = num;
    },
    applyAudition() {
      if (!this.name) {
        this.namep = "姓名不能为空";
        this.nameStyle = "border: 1px solid red;";
        return;
      }
      if (!this.mobile) {
        this.mobilep = "电话不能为空";
        this.mobileStyle = "border: 1px solid red;";
        return;
      } else {
        if (this.mobile.length < 11) {
          this.mobile = "";
          this.mobilep = "请输入正确的手机号码";
          this.mobileStyle = "border: 1px solid red;";
          return;
        }
      }
      console.log(this.num);
      if (this.num == 1) {
        console.log("执行");
        this.submit();
      } else if (this.num == 2) {
        this.submit2();
      }
    },
    async submit() {
      const res = await this.axios.post("api/index/audition", {
        name: this.name,
        age: this.age,
        mobile: this.mobile,
        province: this.province,
        city: this.city,
        address: this.address,
      });
      if (res.status == 200 && res.data.code == 1) {
        this.showAudition = false;
        this.showWeb = false;
        const h = this.$createElement;
        this.$notify({
          title: "提示",
          message: h("i", { style: "color: teal" }, res.data.msg),
        });
      }
    },
    async submit2() {
      const res = await this.axios.post("api/index/online", {
        name: this.name,
        mobile: this.mobile,
        province: this.province,
        city: this.city,
      });
      if (res.status == 200 && res.data.code == 1) {
        this.showAudition = false;
        const h = this.$createElement;
        this.$notify({
          title: "提示",
          message: h("i", { style: "color: teal" }, res.data.msg),
        });
        this.name = "";
        this.mobile = "";
      }
    },
    changPeration(num) {
      this.showAudition = true;
      this.show = false;
      this.showStyle = "height:292px";
      this.num = num;
    },
  },
};
</script>


<style lang="less">
.information {
  width: 1.7rem;
  height: 5.25rem;
  position: fixed;
  right: 0.34rem;
  bottom: 10%;
  background: orange;
  background: url("~img/109.png") no-repeat;
  background-size: contain;
  padding-top: 1.36rem;
  z-index: 99;
  ul {
    li {
      height: 0.8rem;
      border-bottom: 1px solid #efefef;
      width: 0.73rem;
      margin: auto;
      cursor: pointer;
      position: relative;
      img {
        display: block;
        width: 0.56rem;
        margin: auto;
        margin-top: 0.18rem /* 18/100 */;
        transition: 0.5s all;
      }
      img:hover {
        transform: scale(1.1);
      }
      .erweim {
        position: absolute;
        top: -0.4rem;
        left: -2rem;
        width: 1.5rem;
        display: none;
      }
    }
    li:nth-child(1):hover {
      .erweim {
        display: block;
      }
    }
    li:last-child {
      border: none;
      img {
        width: 0.75rem;
      }
    }
  }
}
.Audition {
  width: 379px;
  height: 379px;
  // background: url("~img/114.png") no-repeat;
  background-size: 100% 100%;
  position: fixed;
  bottom: 10%;
  right: 2.34rem;
  padding-top: 95px;
  padding-left: 47px;
  transition: 0.5s all;
  z-index: 99;
  .close {
    width: 15px;
    position: absolute;
    top: 29px;
    right: 29px;
    cursor: pointer;
  }
  .a_bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .b_bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  ul {
    position: relative;
    li {
      margin-bottom: 10px;
      height: 31px;
      span {
        font-size: 14px;
        color: #5f5d5d;
        font-weight: 600;
        width: 58px;
        display: inline-block;
        margin-right: 13px;
      }
      input {
        width: 207px;
        height: 31px;
        border-radius: 10px;
        background: #efefef;
        padding-left: 15px;
      }
      .distpicker-address-wrapper {
        display: inline-block;
        select {
          font-size: 12px;
          padding: 0 10px 0 10px;
          line-height: 31px;
          height: 31px;
          max-width: 93px;
          background: #efefef;
        }
      }
    }
    li:last-child {
      margin-bottom: 12px;
    }
  }
  button {
    width: 138px;
    height: 46px;
    background: url("~img/115.png") no-repeat;
    background-size: contain;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    cursor: pointer;
  }
}
.web-info {
  width: 90%;
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translate(-50%);
  // background: #000;
  .clous {
    width: 0.64rem;
    margin: auto;
  }
  .btn-taggle {
    width: 20%;
    height: 5%;
    // background: orange;
    position: absolute;
    left: 40%;
    top: 4%;
  }
  .Telephone {
    width: 20%;
    height: 20%;
    // background: skyblue;
    display: block;
    position: absolute;
    right: 10%;
    bottom: 12%;
  }
  .input-box {
    width: 95%;
    position: absolute;
    // background: orchid;
    left: 50%;
    transform: translate(-50%);
    top: 15%;
    ul {
      width: 80%;
      margin: auto;
      li {
        margin-bottom: 10px;
        height: 31px;
        span {
          font-size: 12px;
          color: #5f5d5d;
          font-weight: 600;
          width: 58px;
          display: inline-block;
          margin-right: 13px;
        }
        input {
          width: 70%;
          height: 31px;
          border-radius: 10px;
          background: #efefef;
          padding-left: 15px;
        }
        .distpicker-address-wrapper {
          display: inline-block;
          select {
            font-size: 12px;
            padding: 0 10px 0 10px;
            line-height: 31px;
            height: 31px;
            max-width: 88px;
            background: #efefef;
          }
        }
      }
      li:last-child {
        margin-bottom: 12px;
      }
    }
    .btn-submit {
      width: 2.35rem;
      margin: auto;
      margin-top: 20px;
    }
  }
}
</style>