<template>
  <div class="footer">
    <div class="content">
      <div class="text">
        <p class="p1">联系方式</p>
        <p class="p2">电话：400-660-1581</p>
        <p class="p3">
          地址：（北京总部）北京市通州区新华西街58号万达广场C座2305
        </p>
        <p class="p4">商务合作：heyanlin@einstein.top</p>
      </div>
      <ul>
        <li>
          <img src="~img/index/32.png" />
          <p>官方公众号 二维码</p>
        </li>
        <li>
          <img src="~img/index/33.png" />
          <p>咨询服务微信 二维码</p>
        </li>
      </ul>
    </div>

    <div class="beian">
      <img src="~img/beian.png">
      <a href="https://beian.miit.gov.cn/">京ICP备20019424号-2</a>
      <span>爱因斯坦思维科学馆 3-12岁科学·思维融合教育开创者</span>
    </div>
  </div>
</template>


<script>
import "element-ui/lib/theme-chalk/display.css";
export default {};
</script>


<style lang="less" scoped>
.footer {
  .content {
    background: #383838;
    min-height: 2.69rem /* 269/100 */;
    padding-left: 2.69rem /* 269/100 */;
    padding-top: 0.57rem /* 57/100 */;
    display: flex;
    flex-wrap: wrap;
    .text {
      color: #fff;
      font-size: 0.18rem /* 18/100 */;
      line-height: 0.37rem /* 37/100 */;
      .p1 {
        font-size: 0.25rem /* 25/100 */;
        margin-bottom: 0.36rem /* 36/100 */;
      }
      margin-right: 2.04rem /* 204/100 */;
    }
    ul {
      li {
        width: 1.01rem /* 101/100 */;
        float: left;
        margin-right: 1.12rem /* 112/100 */;

        img {
          width: 1.01rem /* 101/100 */;
          height: 1.01rem /* 101/100 */;
        }
        p {
          text-align: center;
          margin-top: 0.1rem /* 10/100 */;
          color: #fff;
        }
      }
    }
  }
  .beian {
    height: 0.85rem;
    background: #000;
    padding-left: 2.69rem /* 269/100 */;
    display: flex;
    align-items: center;
    img {
      width: 0.25rem;
      height: 0.25rem;
      margin-right: 0.2rem;
    }
    a {
      font-size: 0.2rem;
      color: #555;
    }
    a:hover {
      color: #ff4e00;
    }
    span {
      font-size: 0.2rem;
      color: #ffffff;
      margin-left: 0.5rem;
    }
  }
}
@media (max-width: 750px) {
  .footer {
    .content {
      padding-left: 10px;
    }
    .beian {
      padding-left: 10px;
    }
    .text {
      margin-bottom: 20px;
    }
  }
}
</style>